import LoginForm from './LoginForm';
import NavBar from './NavBar';
import { useRouter } from 'next/router';
import { useAuth } from '@developers/single-sign-on';
import hasPermission from '../utils/hasPermission';
import * as Sentry from '@sentry/react';


const Layout = (props) => {
  const router = useRouter();
  const auth = useAuth();

  React.useEffect(() => {
    if (auth.session_data) {
      Sentry.setUser({
        id: auth.session_data.sso_id,
        name: auth.session_data.name
      });
    }
  }, [auth.isAuthenticated]);

  const canViewAdminDashboard = hasPermission(auth.session_data.permissions, 'view-admin-reports');

  return (
    <div style={{padding: '15px'}}>
      <NavBar 
        page={router.pathname}
        canViewAdminDashboard={canViewAdminDashboard}
        logout={auth.logout} 
        isAuthenticated={auth.isAuthenticated}
      />
      {auth.isAuthenticated && auth.session_data.isEmployee ?
        props.children
      :
        <LoginForm 
          loading={auth.loginMeta.loading || auth.verifyMeta.loading}
          loginFailure={auth.loginMeta.failure}
          loginMessage={auth.loginMeta.message}
          login={auth.login} 
          isNotEmployeeAccount={auth.isAuthenticated && !auth.session_data.isEmployee}
        />
      }
    </div>
  )
}

export default Layout;