const formatPhone = (phone) => {
  if (phone) {
    if (phone.includes('-')) {
      let phoneArray = phone.split('-');
      return '(' + phoneArray[0] + ')' + '-' + phoneArray[1] + '-' + phoneArray[2];
    }
  
    let phoneArray = phone.split('');
    const areaCode = '(' + phoneArray[0] + phoneArray[1] + phoneArray[2] + ')';
    const middle = phoneArray[3] + phoneArray[4] + phoneArray[5];
    const end = phoneArray[6] + phoneArray[7] + phoneArray[8] + phoneArray[9];
  
    return areaCode + '-' + middle + '-' + end;
  }

  return null;
}

export default formatPhone;