import Button from 'react-bootstrap/Button';
import Link from 'next/link';

const NavBar = (props) => {

  return (
    <div style={{borderRadius: '25px', color: 'white', backgroundColor: '#007bff', marginBottom: '15px'}}>
      <h2 style={{padding: '5px', display: 'inline-block'}}>OST Call Manager</h2>
      {props.isAuthenticated &&
        <>
          <Button 
            style={{float: 'right', height: '55px', marginRight: '50px'}}
            onClick={() => props.logout()}
          >
            Logout
          </Button>
          <NavButton
            show={ props.page != '/'}
            label='Home' 
            page='/'
          />
          <NavButton
            show={props.canViewAdminDashboard && props.page != '/admin'}
            label='Admin' 
            page='/admin'
          />
        </>
      }
    </div>
  );
}

export default NavBar;

const NavButton = (props) => {
  const styles = {button: {float: 'right', height: '55px', marginRight: '50px'}}

  if (!props.show) {
    return null;
  }

  return (
    <Link href={props.page}>
      <Button
        style={styles.button}
      >
        {props.label}
      </Button>
    </Link>
  );
}
