import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {useState} from 'react';

const LoginForm = (props) => {

  const [form, setForm] = useState({email: '', password: ''});

  const handleSubmit = (e) => {
    e.preventDefault();
    props.login(form);
  }

  if (props.loading) {
    return <div>Loading...</div>
  }

  return (
    <div style={{margin: '15px', width: '200px'}}>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control 
            name="email" 
            value={form.email} 
            onChange={(e) => setForm({...form, email: e.target.value})}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Password</Form.Label>
          <Form.Control 
            name="password" 
            value={form.password} 
            type="password"
            onChange={(e) => setForm({...form, password: e.target.value})}
          />
        </Form.Group>
          <Button
            variant="primary"
            type="submit"
            disabled={form.email == '' || form.password == ''}
           >
            Log in
         </Button>
         <ErrorMessage 
          hasError={props.isNotEmployeeAccount || props.loginFailure}
          message={props.isNotEmployeeAccount ? 'User must be an employee' : props.loginMessage}
         />
      </Form>
    </div>
  )
}

export default LoginForm;

const ErrorMessage = (props) => {
  return <div style={{color: 'red', marginTop: '10px'}}>{props.message}</div>
}